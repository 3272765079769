/* Global CSS */

@import url('https://fonts.googleapis.com/css?family=Quicksand');

body {
    margin: 0;
    font-family: "Quicksand", serif;
    -webkit-font-smoothing: "Quicksand", serif;
    -moz-osx-font-smoothing: "Quicksand", serif;
    font-style: normal;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
  :root {
    --primary: #103a01;
    --bg-shade: #EADDCA;
    --github: #2b3137;
    --black: #000000;
    --white: #ffffff;
  }
  .btn {
    font-family: "Quicksand";
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
  }
  .btn-outline-primary {
    color: var(--primary);
    background-color: var(--white);
    border: 1px solid var(--primary);

  }
  .btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);

  }
  .btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
    margin-top: 20px;
  }
  .btn-primary-contact--form--btn {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
    margin-top: 20px;
  }
  .btn-primary-contact--form--btn:hover {
    color: var(--primary);
    background-color: var(--white);
  }
  .btn-primary:hover {
    color: var(--primary);
    background-color: var(--white);
  }
  .btn-github {
    color: var(--github);
    background-color: var(--white);
    border: var(--white);
    display: flex;
    flex-direction: row;  
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--white);
    background-color: var(--github);
  }
  /* Global CSS Ends */
  /* Main Headings */
  
  /* Heading 1 */
  h1 {
    font-size: 56px;
    font-weight: 700;
    line-height: 67px;
  }
  /* Heading 2 */
  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
  }
  /* Heading 3 */
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }
  
  /* Pragraphs text large medium small */
  /* Body 1 */
  .text-lg {
    color: var(--primary);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }
  /* Body 2 */
  .text-md {
    color: var(--primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
  }
  
  /* Section Titles */
  /* Skills & About Me */
  .section--title {
    color: var(--primary);
    font-size: 30px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: flex-start;
  }
  /* Section Titles Ends */
  
  /* Navbar Style Start */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--white);
    box-shadow: 0px 10px 80px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .navbar--items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 50px;
    text-decoration: none;
  }
  .navbar--items ul > li > a {
    text-decoration: none;
  }
  /* Navbar Content */
  .navbar--content {
    color: var(--black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }
  .navbar--active-content {
    color: var(--primary);
  }
  /* Navbar Styler Ends */
  
  /* Hero Section Style */
  .hero--section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 40px;
    padding-right:50px;
    padding-left: 80px;
    padding-bottom: 0px;

    align-items: center;
    justify-content: space-between;
    gap: 32px;
    background-color: var(--bg-shade);
  }
  .hero--section--content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .hero--section--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.333px;
  }
  .hero--section--content--box > button {
    margin-top: 21.333px;
  }
  .hero--section--title {
    color: var(--primary);
    font-size: 74.667px;
    font-weight: 700;
    line-height: 90px;
    align-self: stretch;
  }
  .hero--section--title--color {
    color: var(--primary);
  }
  .hero--section-description {
    color: var(--primary);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  .hero--section--img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    overflow: hidden;
  }
  
  .hero--section--img > img {
    width: 1050px; 
    height: auto;
  }
  
/* Skills Section Style */
.skills--section {
    display: flex;
    padding: 50px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center-align the entire section */
    gap: 20px; /* Add spacing between the section title and cards */
}

.skills--section .section--title {
    color: var(--primary);
    font-size: 30px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0; /* Remove extra bottom margin */
}

.skills--section .skills--section--heading {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin-top: 0; /* Remove extra top margin */
}

.skills--section--container {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for the skills section */
    position: relative;
    width: 100%; /* Ensure the container spans the full width */
}

.skills--section--card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content within each card */
    justify-content: space-between; /* Even spacing between content */
    background: var(--bg-shade);
    padding: 32px;
    min-height: 450px; /* Ensure consistent card height */
    max-height: 450px; /* Prevent height changes */
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

.skills--section--card:hover {
    transform: scale(1.02); /* Subtle hover effect */
    border-left: 4px solid var(--primary);
}

.skills--section--img {
    display: flex;
    justify-content: center; /* Center the top image */
    align-items: center;
    width: 100%;
    margin-bottom: 16px; /* Add spacing below the top image */
}

.skills--section--img2 {
    display: flex;
    justify-content: center; /* Center the bottom image */
    align-items: center;
    width: 100%;
    margin-top: auto; /* Push the bottom image to the end */
    height: 40px; /* Ensure consistent height for bottom images */
}

.skills--section--card--content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content */
    gap: 16px;
    text-align: center;
    flex: 1; /* Ensure content grows properly within the card */
}

.skills--section--title {
    color: var(--primary);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px; /* Add spacing below the title */
    margin-top: 0; /* Ensure consistent alignment */
    text-align: center;
}

.skills--section--description {
    color: var(--black);
    font-size: 16px; /* Adjust font size */
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 120px; /* Fixed height for text */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allow multiline text */
}

/* Fix spacing between the title and cards */
.skills--section .skills--section--heading + .skills--section--container {
    margin-top: 20px; /* Add spacing between heading and container */
}


  
  /* About Me */
  .about--section {
    position: relative;
    display: grid;
    padding: 50px 80px;
    align-items: center;
    gap: 100px;
    grid-template-columns: repeat(2, 1fr);
  }
  .about--section--img > img {
    width: 100%;
    height: 100%;
    border-top: 20px solid #000000;
    border-bottom: 20px solid #000000;
    margin-top: 70px;
  }
  /* About Me Ends */
  
  /* My Portfolio Starts */
  .portfolio--section {
    display: flex;
    padding: 20px 80px;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
  }
  .portfolio--container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .portfolio--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.3px;
  }
  .portfolio--section--container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 42.6px;
    width: 100%;
  }
  .portfolio--section--img {
    border-radius: 8px;
    width: 100%;
  }
  .portfolio--section--img > img {
    width: 100%;
  }
  .portfolio--section--card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .portfolio--section--card--content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }
  .portfolio--section--title {
    color: var(--primary);
  }
  .portfolio--link {
    text-decoration: none;
    display: flex;
    gap: 18px;
    padding-bottom: 10.6px;
    font-weight: 600;
  }
  .portfolio--section--card:hover path {
    stroke: var(--primary);
  }
  /* Portfolio Ends */
  
  /* Contact Starts */

  .contact--section {
    display: flex;
    padding: 50px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
  .contact--form--container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width: 40%;
    row-gap: 32px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 32px;
    row-gap: 32px;
  }
  .contact--label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
    width: 100%;
  }
  .contact--input {
    display: flex;
    font-family: "Quicksand";
    padding: 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.333px solid var(--primary);
    background: var(--white);
  }

  .success-message {
    border: 2px solid #4caf50; /* Green border */
    padding: 10px;
    margin-bottom: 20px;
    color: #4caf50; /* Green color */
  }
  
  .error-message {
    border: 2px solid #f44336; /* Red border */
    padding: 10px;
    margin-bottom: 20px;
    color: #f44336; /* Red color */
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
      white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
      white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
  }
  /*For IE*/
  select::-ms-expand {
    display: none;
  }
  .checkbox--label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10.6px;
    flex: 1 0 0;
    justify-content: flex-start;
  }
  input[type="checkbox"] {
    width: 21px;
    height: 21px;
    border: 0.15em solid var(--primary);
    border-radius: 0.15em;
    transform: translatey(-0.075em);
  }
  /* Contact End */
  
  /* Footer Start */
  /* Footer Starts */
  .footer--container {
    display: flex;
    padding: 80px 80px;
    flex-direction: column;
    align-items: flex-start;
    background: var(--bg-shade);
    align-items: center;
    align-self: stretch;
  }
  .footer--link--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .footer--items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 50px;
    text-decoration: none;
  }
  .footer--items ul > li > a {
    text-decoration: none;
    cursor: pointer;
  }
  .footer--social--icon > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    text-decoration: none;
  }
  .divider {
    margin: 106px 0 50px;
    height: 1.333px;
    width: 100%;
    background: rgba(40, 41, 56, 0.55);
  }
  .footer--content--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .footer--content {
    color: var(--black);
    font-size: 18.667px;
    font-weight: 400;
    line-height: 28px;
  }
  
  /* Responsive Screens */
  @media only screen and (max-width: 1800px) {
    .hero--section--title {
      font-size: 68px;
      line-height: 70px;
    }
    .skills--section--title {
      font-size: 28px;
      line-height: 40px;
    }
  }
  
  @media only screen and (max-width: 1600px) {
    .skills--section--container {
      gap: 16px;
    }
    .skills--section--card {
      gap: 28px;
    }
    .skills--section--card--content {
      gap: 20px;
    }
    .skills--section--title {
      font-size: 25px;
      line-height: 30px;
      align-self: center;
    }
    .skills--section--description {
      font-size: 15px;
      line-height: 30px;
      text-align: center;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .btn-outline-primary {
      display: none;
    }
    .btn-primary {
      margin-top: 40px;
      display: block;
    }
    .hero--section--img {
      margin-top: 200px;
    }
    .hero--section {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 70px;
    }
    .hero--section--title,
    .hero--section-description,
    .footer--content {
      text-align: center;
    }
    .skills--section--container,
    .about--section,
    .portfolio--section--container,
    .portfolio--container-box,
    .footer--link--container,
    .footer--items > ul,
    .footer--content--container {
      display: flex;
      flex-direction: column;
    }
    .skills--section,
    .portfolio--container,
    .hero--section--content,
    .hero--section--content--box {
      align-items: center;
    }
    .portfolio--container-box {
      gap: 30px;
    }
    .skills--section,
    .testimonial--section,
    .contact--section,
    .footer--container {
      gap: 20px;
      padding-top: 70px;
      padding-bottom: 70px;
    }
    .about--section {
      gap: 20px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .testimonial--section--card {
      padding: 25px;
    }
    .hero--section--title,
    .sections--heading {
      font-size: 35px;
      line-height: 40px;
    }
    .hero--section--content--box {
      gap: 10px;
    }
    .contact--form--container {
      width: 100%;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .footer--social--icon > ul,
    .footer--items > ul {
      padding: 0;
      align-items: center;
    }
    .divider {
      margin: 20px;
    }
  }
  
  /* Hamburger menu  */
  
  .nav__hamburger {
    display: none;
    width: 1.875rem;
    height: 1.313rem;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 27px;
    right: 25px;
  }
  
  .nav__hamburger__line {
    display: block;
    height: 0.2rem;
    width: 100%;
    background-color: #000000;
    border-radius: 0.625rem;
    transition: all ease-in-out 0.2s;
  }
  
  @media screen and (max-width: 1200px) {
    .nav__hamburger {
      display: flex;
      z-index: 200;
  
    }
    .navbar--items {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255);
      top: -20rem;
      width: 100%;
      transition: all ease-in-out 0.4s;
      height: auto;
      max-height: 0;
      overflow: hidden;
    }
    .navbar--items ul {
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 1rem 0;
    }
    .navbar--items ul li {
      text-align: center;
    }
    .navbar--items ul li a {
      padding: 0.1rem;
    }
    .navbar--items.active {
      top: 1px;
      max-height: 500px;
      overflow: visible;
    }
    .nav__hamburger.active :nth-child(1) {
      transform: rotate(45deg) translate(0.45rem, 0.1875rem);
    }
  
    .nav__hamburger.active :nth-child(2) {
      opacity: 0;
    }
  
    .nav__hamburger.active :nth-child(3) {
      transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
    }

    .skills--section--img {
      display: flex;
      padding-left: 60px;
    }
    .skills--section--img2 {
      display: none;
    }
  }
  /* Responsive Screens Ends*/